<template>
  <div :class="state.statusClass" @click="actions.openSalesDetailModal()">
    <div class="sales-item-mo">
      <card-basic>
        <p class="id-number sub-text-s2 text-gray-second">
          {{ saleItem.merchantUid }}
        </p>
        <h4>
          {{ saleItem.title }}
        </h4>
        <ul class="sales-contents">
          <li>
            <span class="b-text-3 text-gray-second">판매 일시</span>
            <span class="b-text-1"> {{ state.soldAt }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">구매자</span>
            <span class="b-text-1">{{ state.userName }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">구매 상태</span>
            <span class="b-text-1 status">
              <span>{{ state.status.text }}</span>
              <help-tooltip
                v-if="state.isRefund"
                icon-color="#FF4646"
                :tooltip-text="`상품 결제 후 판매자가 일부 금액을 할인한 상태예요<br/>(구매자의 시청 권한은 유지됩니다)`"
              ></help-tooltip>
            </span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">판매 금액</span>
            <span class="b-text-1">{{ state.soldPrice }}</span>
          </li>
          <li class="calculate">
            <span class="b-text-3 text-gray-second">
              정산 예정 금액
              <help-tooltip
                :tooltip-text="`정산 예정 금액은 소득세와 포인트가 합산되지 않은 <br />
              금액으로 실수령 금액과 차이가 있을 수 있어요`"
              ></help-tooltip>
            </span>
            <span class="price">
              {{ state.settlementAmount }}
            </span>
          </li>
        </ul>
      </card-basic>
    </div>

    <div class="sales-item-pc">
      <div class="sales-item">
        <span class="col1 b-text-2">
          {{ state.soldAt }} <br />{{ saleItem.merchantUid }}
        </span>
        <span class="col2 b-text-2"> {{ saleItem.title }} </span>
        <span class="col3 b-text-2">{{ state.userName }}</span>
        <span class="col4 b-text-2 status">
          <span>{{ state.status.text }}</span>
          <help-tooltip
            v-if="state.isRefund"
            icon-color="#FF4646"
            :tooltip-text="`상품 결제 후 판매자가 일부 금액을 할인한 상태예요<br/>(구매자의 시청 권한은 유지됩니다)`"
          ></help-tooltip>
        </span>
        <span class="col5 b-text-2">{{ state.soldPrice }}</span>
        <span class="col6 b-text-2 price">
          {{ state.settlementAmount }}
        </span>
      </div>
    </div>

    <teleport :disabled="true">
      <sale-detail-modal
        v-if="state.showSalesDetailModal"
        :sale-item="saleItem"
        @hideModal="actions.closeSalesDetailModal()"
      ></sale-detail-modal>
    </teleport>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import { computed, reactive } from "vue";
import SaleDetailModal from "../SalesDetailModal/SalesDetailModal";
import helper from "@/helper";
import moment from "moment-timezone";
import { settlementPriceFormat } from "@/helper/settlement";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";
import settlementConst from "@/consts/settlement";

export default {
  name: "SalesItem",
  components: { HelpTooltip, SaleDetailModal, CardBasic },
  props: {
    saleItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      status: computed(() => {
        const saleStatus = Object.keys(settlementConst.saleStatus).find(
          (key) =>
            settlementConst.saleStatus[key].value === props.saleItem.statusCode
        );
        return settlementConst.saleStatus[saleStatus];
      }),
      statusClass: computed(() => {
        switch (state.status.value) {
          case settlementConst.saleStatus.complete.value:
            return "status-success";
          case settlementConst.saleStatus.refund.value:
          case settlementConst.saleStatus.refundPercent.value:
            return "status-refund";
          case settlementConst.saleStatus.DCAll.value:
          case settlementConst.saleStatus.DCPercent.value:
            return "status-discount";
          default:
            return "";
        }
      }),
      isRefund: computed(() => {
        return (
          state.status.value ===
            settlementConst.saleStatus.refundPercent.value ||
          state.status.value === settlementConst.saleStatus.DCPercent.value
        );
      }),
      settlementAmount: computed(() => {
        return settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.settlementAmount
        );
      }),
      showSalesDetailModal: false,
      userName: computed(() => {
        return helper.displayUserName(
          props.saleItem.currency,
          props.saleItem.user
        );
      }),
      soldAt: computed(() => {
        return moment(props.saleItem.createdAt).format("YYYY.MM.DD");
      }),
      soldPrice: computed(() => {
        return `${settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.saleAmount
        )}`;
      }),
    });

    const actions = {
      openSalesDetailModal: () => {
        state.showSalesDetailModal = true;
      },
      closeSalesDetailModal: () => {
        state.showSalesDetailModal = false;
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
