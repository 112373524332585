<template>
  <base-modal-side
    :is-dimmed="true"
    :auto-hide="true"
    @closeModal="actions.closeModal()"
  >
    <template #modalHeader>
      <h3 class="modal-title">판매 내역 상세</h3>
    </template>

    <template #modalBody>
      <h4>
        {{ saleItem.title }}
      </h4>

      <ul class="sales-info" :class="state.statusClass">
        <li>
          <span class="sub-text-s2 text-gray-second">구매 일시</span>
          <span class="sub-title-s2">{{ state.soldAt }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">구매자</span>
          <span class="sub-title-s2">{{ state.userName }}</span>
        </li>
        <!--  구매 상태 분기처리 필요  -->
        <li>
          <span class="sub-text-s2 text-gray-second">구매 상태</span>
          <span class="sub-title-s2 status">
            <span>{{ state.status.text }}</span>
            <help-tooltip
              v-if="state.isPartialRefund"
              container=".side-modal"
              icon-color="#FF4646"
              placement="auto"
              :tooltip-text="`상품 결제 후 판매자가 일부 금액을 할인한 상태예요<br/>(구매자의 시청 권한은 유지됩니다)`"
            ></help-tooltip>
          </span>
        </li>
      </ul>

      <div v-if="state.isRefund" class="reason-box">
        <p class="b-text-2">{{ state.refundReason }}</p>
      </div>

      <hr class="hr-third" />

      <h5>정산 내역</h5>

      <ul class="calculate-info" :class="state.statusClass">
        <li>
          <span class="sub-text-s2 text-gray-second">판매 금액</span>
          <span class="sub-title-s2">{{ state.amount }}</span>
        </li>
        <li v-if="state.isRefund">
          <span class="sub-text-s2 text-gray-second">환불 금액</span>
          <span class="sub-title-s2">{{ state.cancelAmount }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">부가세</span>
          <span class="sub-title-s2">{{ state.VAT }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">PG 수수료</span>
          <span class="sub-title-s2">{{ state.pgFee }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">서비스 이용 수수료</span>
          <span class="sub-title-s2">{{ state.serviceFee }}</span>
        </li>
        <li>
          <span class="txt sub-text-s2">
            정산 예정 금액
            <help-tooltip
              container=".side-modal"
              :tooltip-text="`빅크 포인트는 콘텐츠 판매 금액의 10%까지 <br />정산 가능하며, 자동 정산됩니다.`"
              icon-color="#0D0D10"
            ></help-tooltip>
          </span>
          <span class="sub-title-s2 price">
            {{ state.settlementAmount }}
          </span>
        </li>
      </ul>
    </template>

    <template #modalFooter>
      <button class="modal-footer-btn" @click="actions.closeModal()">
        확인
      </button>
    </template>
  </base-modal-side>
</template>
<script>
import BaseModalSide from "../../../../components/common/BaseModalSide";
import helper from "@/helper";
import moment from "moment-timezone";
import { computed, reactive } from "vue";
import { settlementPriceFormat } from "@/helper/settlement";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";
import settlementConst from "@/consts/settlement";

export default {
  name: "SaleDetailModal",
  components: { HelpTooltip, BaseModalSide },
  props: {
    saleItem: {
      type: Object,
      required: true,
    },
  },
  emits: ["hideModal"],
  setup(props, { emit }) {
    const state = reactive({
      status: computed(() => {
        const saleStatus = Object.keys(settlementConst.saleStatus).find(
          (key) =>
            settlementConst.saleStatus[key].value === props.saleItem.statusCode
        );
        return settlementConst.saleStatus[saleStatus];
      }),
      statusClass: computed(() => {
        switch (state.status.value) {
          case settlementConst.saleStatus.complete.value:
            return "status-success";
          case settlementConst.saleStatus.refund.value:
          case settlementConst.saleStatus.refundPercent.value:
            return "status-refund";
          case settlementConst.saleStatus.DCAll.value:
          case settlementConst.saleStatus.DCPercent.value:
            return "status-discount";
          default:
            return "";
        }
      }),
      isPartialRefund: computed(() => {
        return (
          state.status.value ===
            settlementConst.saleStatus.refundPercent.value ||
          state.status.value === settlementConst.saleStatus.DCPercent.value
        );
      }),
      isRefund: computed(() => {
        return (
          state.status.value === settlementConst.saleStatus.refund.value ||
          state.status.value ===
            settlementConst.saleStatus.refundPercent.value ||
          state.status.value === settlementConst.saleStatus.DCAll.value ||
          state.status.value === settlementConst.saleStatus.DCPercent.value
        );
      }),
      refundReason: computed(() => {
        return `환불 사유 : ${props.saleItem.cancelReason}`;
      }),
      calculatePrice: computed(() => {
        return settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.amount
        );
      }),
      showSalesDetailModal: false,
      userName: computed(() => {
        return helper.displayUserName(props.saleItem.user);
      }),
      soldAt: computed(() => {
        return moment(props.saleItem.createdAt).format("YYYY.MM.DD");
      }),
      saleAmount: computed(() => {
        return settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.saleAmount
        );
      }),
      amount: computed(() => {
        return settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.amount
        );
      }),
      cancelAmount: computed(() => {
        return `-${settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.cancelAmount
        )}`;
      }),
      settlementAmount: computed(() => {
        return settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.settlementAmount
        );
      }),
      VAT: computed(() => {
        return `-${settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.vat
        )}`;
      }),
      pgFee: computed(() => {
        return `-${settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.pgFees
        )}`;
      }),
      serviceFee: computed(() => {
        return `-${settlementPriceFormat(
          props.saleItem.currency,
          props.saleItem.fees
        )}`;
      }),
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
    };

    return { state, actions, settlementConst };
  },
};
</script>

<style src="./style.css" scoped></style>
