<template>
  <div class="sales-container">
    <div class="tab-header">
      <h4>판매내역</h4>
      <div class="filter-month">
        <button
          :disabled="state.getLoading || state.prevMonthDisabled"
          @click="actions.getMonthSales('prev')"
        >
          <arrow-icon
            direction="left"
            stroke-size="normal"
            :fill-color="state.prevMonthDisabled ? '#D0D5DE' : '#0D0D10'"
          ></arrow-icon>
        </button>
        <span class="month sub-title-s1">
          {{ state.filterMonthForUI }}
        </span>
        <button
          :disabled="state.getLoading || state.nextMonthDisabled"
          @click="actions.getMonthSales('next')"
        >
          <arrow-icon
            direction="right"
            stroke-size="normal"
            :fill-color="state.nextMonthDisabled ? '#D0D5DE' : '#0D0D10'"
          ></arrow-icon>
        </button>
      </div>
    </div>

    <div class="table-header">
      <span class="col1 sub-text-s3 text-gray-second">판매 일시/주문번호</span>
      <span class="col2 sub-text-s3 text-gray-second">상품명</span>
      <span class="col3 sub-text-s3 text-gray-second">구매자</span>
      <span class="col4 sub-text-s3 text-gray-second">구매 상태</span>
      <span class="col5 sub-text-s3 text-gray-second">판매 금액(VAT 포함)</span>
      <span class="col6 sub-text-s3 text-gray-second">
        정산 예정 금액
        <help-tooltip
          :tooltip-text="`정산 예정 금액은 소득세와 포인트가 합산되지 않은 <br />
              금액으로 실수령 금액과 차이가 있을 수 있어요`"
        ></help-tooltip>
      </span>
    </div>

    <ul v-if="!state.emptyList" class="sales-list-wrapper">
      <li
        v-for="saleItem in state.saleList"
        :key="`sale-item-${saleItem.resourceId}`"
      >
        <sales-item :sale-item="saleItem"></sales-item>
      </li>
    </ul>

    <empty-list
      v-if="state.emptyList"
      icon-image="/assets/images/settlement/sales_empty.png"
      text="판매된 내역이 없습니다."
    ></empty-list>

    <div
      v-if="state.salesMeta.pagination.lastPage > 1"
      class="pagination-wrapper"
    >
      <pagination
        :pagination="state.salesMeta.pagination"
        @getPageData="(page) => getSettlementSales({ page: page })"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import moment from "moment-timezone";
import { useStore } from "vuex";
import ArrowIcon from "../../../../components/console/icons/ArrowIcon";
import SalesItem from "../SalesItem/SalesItem";
import EmptyList from "../../../../components/console/empties/EmptyList";
import Pagination from "../../../../components/console/pagination/Pagination";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";

export default {
  name: "TabSales",
  components: {
    HelpTooltip,
    Pagination,
    EmptyList,
    SalesItem,
    ArrowIcon,
  },
  setup() {
    const store = useStore();
    const monthFormat = "YYYYMM";

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      saleList: computed(() => {
        return store.getters["settlements/saleList"];
      }),
      salesMeta: computed(() => {
        return store.getters["settlements/saleListMeta"];
      }),
      emptyList: computed(() => {
        return state.saleList.length === 0;
      }),
      getLoading: false,
      locale: computed(() => {
        return store.getters["locale/locale"];
      }),
      filterMonth: "",
      filterMonthForUI: computed(() => {
        const year = state.filterMonth.slice(0, 4);
        const month = state.filterMonth.slice(4, 6);
        return `${year}년 ${month}월`;
      }),
      monthIndex: computed(() => {
        return store.getters["settlements/filterMonthIndex"];
      }),
      nextMonthDisabled: computed(() => {
        return state.filterMonth === moment().format(monthFormat);
      }),
      prevMonthDisabled: computed(() => {
        return (
          moment(state.user.createdAt).format(monthFormat) === state.filterMonth
        );
      }),
    });

    onMounted(() => {
      setFilterMonth();
      getSettlementSales({ date: state.filterMonth });
    });

    const setFilterMonth = () => {
      state.filterMonth = moment()
        .add(state.monthIndex, "months")
        .format(monthFormat);
    };

    const getSettlementSales = (data) => {
      state.getLoading = true;

      let payload = data
        ? {
            date: data.date ? data.date : null,
            page: data.page ? data.page : 1,
          }
        : null;

      store.dispatch("settlements/getSettlementSales", payload).then(() => {
        state.getLoading = false;
      });
    };

    const actions = {
      getMonthSales: (direction) => {
        if (!state.getLoading) {
          if (direction === "prev") {
            store.dispatch("settlements/updateMonthIndex", { direction: -1 });
          } else {
            store.dispatch("settlements/updateMonthIndex", { direction: 1 });
          }
          setFilterMonth();
          getSettlementSales({ date: state.filterMonth });
        }
      },
    };

    return { state, actions, getSettlementSales };
  },
};
</script>

<style src="./style.css" scoped></style>
